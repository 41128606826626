<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      v-if="pageData === undefined"
      variant="danger"
    >
      <h4 class="alert-heading">
        Error fetching page data
      </h4>
      <div class="alert-body">
        No page found with this page id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'admin-cms-pages'}"
        >
          CMS Pages
        </b-link>
        for other pages.
      </div>
    </b-alert>

    <b-card
      v-else
      class="pages-edit-wrapper"
    >

      <!-- form -->
      <b-form class="mt-2">
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <h3 class="mb-0 font-weight-normal">
                Edit Page
              </h3>
            </div>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Title"
              label-for="pages-edit-title"
              class="mb-2"
            >
              <b-form-input
                id="pages-edit-title"
                v-model="pageData.title"
                @input="generateSlug"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Slug"
              label-for="pages-edit-slug"
              class="mb-2"
            >
              <b-form-input
                id="pages-edit-slug"
                v-model="pageData.slug"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Content"
              label-for="pages-edit-content"
              class="mb-2"
            >
              <quill-editor
                id="pages-edit-content"
                v-model="pageData.content"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              variant="primary"
              class="mr-1"
              @click="update"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :to="{ name: 'admin-cms-pages'}"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <!--/ form -->
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BForm, BRow, BCol, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import { onUnmounted, ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import storeModule from '@/views/admin/pages/pagesStoreModule'
import usePagesSetting from '@/views/admin/pages/usePagesSetting'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  setup() {
    const pageData = ref(null)

    const INVOICE_APP_STORE_MODULE_NAME = 'app-cms-pages'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
    const {
      fetchPage,
      updatePage,
    } = usePagesSetting()

    return {
      pageData,
      fetchPage,
      updatePage,
    }
  },
  async created() {
    const pageID = router.currentRoute.params.id
    if (pageID) this.pageData = await this.fetchPage(pageID)
  },
  methods: {
    update() {
      this.updatePage(this.pageData)
      this.$router.push({ name: 'admin-cms-pages' })
    },
    generateSlug(text) {
      if (!(text instanceof Event)) {
        this.pageData.slug = text
          .toLowerCase()
          .replace(/ /g, '-')
          .replace(/[^\w-]+/g, '')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
